import React from "react"

import { useEvents } from "../../utils/useEventsSection"
import { useEventsImages } from "../../utils/useEventsImages"
import { useLanguageContext } from "../../utils/LanguageContext"
import { GatsbyImage } from "gatsby-plugin-image"

const Events = () => {
  const { toggle } = useLanguageContext()
  const data = useEvents()
  const images = useEventsImages()
  const imageFlyers = images[0]
  console.log(images)
  const langFilteredEs = data.filter(i => i.lang === "es")
  const langFilteredEn = data.filter(i => i.lang === "en")

  return (
    <div className="flex flex-col items-center xl:items-start xl:flex-row md:pb-16">
      <div className="max-w-sm xl:w-full flex flex-col items-center text-center lg:text-left lg:items-start mx-auto lg:mx-0 xl:ml-16">
        <h1 className="h1-mobile lg:hidden max-w-md lg:max-w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <h1 className="h1-desktop hidden lg:block lg:pt-8">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <p className="py-4 max-w-md lg:max-w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.paragraph1)
            : langFilteredEn.map(i => i.paragraph1)}
        </p>
      </div>
      <div className=" flex flex-col items-center justify-center sm:justify-around lg:justify-end w-full">
        {imageFlyers.map(i => (
          <GatsbyImage
            className="my-4"
            key={i.id}
            image={i.gatsbyImageData}
            alt="flyer image"
          />
        ))}
      </div>
    </div>
  )
}

export default Events
