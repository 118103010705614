import { useStaticQuery, graphql } from "gatsby"

export const useEventsImages = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryFlyers {
        allContentfulFlyersEventos {
          edges {
            node {
              flyer {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulFlyersEventos.edges.map(i => i.node.flyer)
}
